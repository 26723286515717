@keyframes brightDark {
  0% {
    color: white;
  }
  50% {
    color: black;
  }
  100% {
    color: white;
  }
}

.bright-dark-animation {
  animation: brightDark 3s infinite;
}

.hidden-text {
  display: inline-block;
  width: 20ch; /* Show only 5 characters */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.QTYSelect {
  margin-left: 10px;
}